
import { Component, Vue, Watch } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import CmsShowList from '@/components/views/CmsShow/CmsShowList.vue';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import { NewRouteConfiguration } from '@/interfaces/components/cms/NewRouteConfiguration';
import { generateGivenEntityData, openEntityIndex, setRouteEntity } from '@/helpers/CmsShowHelper';
import CmsShowActions from '@/components/views/CmsShow/CmsShowActions.vue';
import CmsUserTable from '@/components/views/CmsShow/CmsUserTable.vue';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import CmsFieldsCategorySelector from '@/components/views/CmsIndex/CmsFieldsCategorySelector.vue';
import NotAllowedComponent from '@/components/views/settings/NotAllowedComponent.vue';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';

@Component({
    name: 'CmsShow',
    components: {
        SettingsTopBar,
        CmsShowActions,
        CmsShowList,
        CmsUserTable,
        NotAllowedComponent,
    },
})
export default class CmsShow extends mixins<UserRightsMixin>(UserRightsMixin) {
    private entityName: null | CmsEntityTypes = null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private selectedGroup: string = '';
    private showCmsShow: boolean = true;
    private component: any = CmsFieldsCategorySelector;
    private cmsData: any = null;
    private showComponent: boolean = true;
    private userHasRights: boolean = false;

    private get currentEntityData() {
        return generateGivenEntityData(this.entityName);
    }
    private async created() {
        if (this.canSeeCMSItem) {
            this.userHasRights = true;
        }
        if (this.$route.params.entityName === CmsEntityTypes.ProductDetails) {
            if (this.canSeeCatalogueItem) {
                this.userHasRights = true;
            }
        }
        if (this.$route.params.entityName === CmsEntityTypes.ChecklistFields) {
            this.loadingOverlay.start();
            this.entityName = await setRouteEntity(this.$route);
            this.cmsData = generateGivenEntityData(this.entityName);
            this.loadingOverlay.stop();
        }
    }

    private get currentEntityItem() {
        let currentItem: any;
        currentItem = this.cmsData.dataSource.filter((obj: any) => {
            return obj.$id === this.$route.params.entityId;
        });
        return currentItem[0];
    }
    private get currentEntityCategory() {
        if (this.$route.name === RouteNames.cmsIndex && this.$route.params.entityId !== 'new') {
            try {
                let currentItem: any;
                currentItem = this.cmsData.dataSource.filter((obj: any) => {
                    return obj.$id === this.$route.params.entityId;
                });
                let categoryItems;
                categoryItems = this.cmsData.dataSource.filter((obj: any) => {
                    return obj.checklistSegment_id === currentItem[0].checklistSegment_id;
                });
                return categoryItems;
            } catch {
                return null;
            }
        }
        return null;
    }
    private get isAllowedToSearch() {
        return this.entityName === CmsEntityTypes.PostCodeConnections;
    }
    private goToEntityIndex(newRouteConfiguration: NewRouteConfiguration) {
        openEntityIndex(newRouteConfiguration, this.entityName);
    }
    @Watch('$route', { deep: true, immediate: true })
    private async onRouteChange() {
        this.showCmsShow = this.$route.name === RouteNames.cmsShow;
        if (
            this.$route.params.entityName !== CmsEntityTypes.ChecklistFields &&
            this.$route.params.entityName !== CmsEntityTypes.IntroScreenItem
        ) {
            this.loadingOverlay.start();
            try {
                if (this.$route.params.entityName !== CmsEntityTypes.UserGroupConnections) {
                    this.showCmsShow = this.$route.name === RouteNames.cmsShow;
                    this.entityName = await setRouteEntity(this.$route);
                    this.cmsData = generateGivenEntityData(this.entityName);
                } else {
                    this.showComponent = false;
                }
            } catch (e) {
                this.$router.push({ name: RouteNames.home });
                return;
            } finally {
                this.loadingOverlay.stop();
            }
        }
    }
}
