
import { Component, Prop, Vue } from 'vue-property-decorator';
import { generateDynamicGroupedList } from '@/helpers/GroupedListHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ShowEntityData } from '@/interfaces/components/cms/ShowEntityData';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import PostCodeConnection from '@/models/PostCodeConnection';
import { mapTranslationByKey } from '@/helpers/TranslationHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { RouteNames } from '@/enums/routes/RouteNames';

@Component({
    name: 'CmsShowList',
})
export default class CmsShowList extends Vue {
    @Prop({ required: true }) private entityData!: ShowEntityData;
    @Prop({ default: '' }) private selectedGroup!: string;
    @Prop({ default: false }) private isAllowedToSearch!: boolean;
    @Prop({ default: null }) private entityName!: null | string;

    private cmsEntityTypes = CmsEntityTypes;
    private searchQuery = '';
    private searchedEntities = [];

    private get filteredEntityFields() {
        if (this.searchQuery === '') {
            return this.groupedEntityFields;
        }

        return this.groupedEntityFields.filter((groupedEntityField: any) => {
            if (groupedEntityField.children) {
                return this.groupedEntityFields;
            }

            return groupedEntityField.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
    }
    private itemTitle(item: any) {
        switch (this.entityName) {
            case CmsEntityTypes.TaxDetails:
                return `${item.amount}% - ${item.description || ''}`;
            case CmsEntityTypes.Taxes:
                return item.country.name;
            case CmsEntityTypes.EmailTemplateProperties:
                return item.locale.name;
            case CmsEntityTypes.BankAccount:
                return `${item.bankName} - ${item.accountNumber}`;
            case CmsEntityTypes.FlaggedPdfValues:
            case CmsEntityTypes.WarrantyWarnings:
                return item.value;
            case CmsEntityTypes.DimensionsWizard:
                return item.productSystem.name;
            default:
                return item.name;
        }
    }

    private get groupedEntityFields() {
        if (this.entityData.groupByProperties == null) {
            return this.entityData.dataSource;
        }
        let groupedList = generateDynamicGroupedList(this.entityData.dataSource, this.entityData.groupByProperties);
        if (this.selectedGroup !== '') {
            groupedList = [groupedList[this.selectedGroup]];
        }
        return groupedList;
    }

    private onEditChecklistField(item: any) {
        const navigationItem = {
            entityId: item.id,
            additionalEntityId: null,
        };
        if (this.$route.params.entityName === CmsEntityTypes.PdfOfferOptions) {
            navigationItem.additionalEntityId = item.offerPdfProperties_id;
        }
        if (this.$route.params.entityName === CmsEntityTypes.PdfOrderTemplatesOptions) {
            navigationItem.additionalEntityId = item.orderPdfProperties_id;
        }
        if (this.$route.params.entityName === CmsEntityTypes.DimensionsWizard) {
            this.$router.push({
                name: RouteNames.newProductSystemWizard,
                params: {
                    entityId: item.id,
                },
            });
            return;
        }

        this.$emit(EventBusEvents.openEntityIndex, navigationItem);
    }

    private renderPostCodes(postCodeConnections: PostCodeConnection[]) {
        if (postCodeConnections.length <= 0) {
            return this.$t('Nema postavljenih poštanskih brojeva');
        }

        return postCodeConnections
            .map((postCodeConnection: PostCodeConnection) => {
                return `
      ${postCodeConnection.fromNumber}
       - ${postCodeConnection.toNumber}
       (${postCodeConnection.country ? postCodeConnection.country.code : '-'})
       `;
            })
            .join(', ');
    }

    private onSearch(query: string) {
        this.searchQuery = query;
    }

    private optionTranslation(key: string) {
        if (!this.entityData.shouldLabelsBeTranslated) {
            return key;
        }
        return this.$t(mapTranslationByKey(key));
    }
}
