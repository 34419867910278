
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import CmsShowList from '@/components/views/CmsShow/CmsShowList.vue';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import CmsShowActions from '@/components/views/CmsShow/CmsShowActions.vue';
import CmsUserTable from '@/components/views/CmsShow/CmsUserTable.vue';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';

@Component({
  name: 'CmsFieldsCategorySelector',
  components: { SettingsTopBar, CmsShowActions, CmsShowList, CmsUserTable },
})
export default class CmsFieldsCategorySelector extends Vue {
  @Prop({ required: false }) private data!: any;
  @Prop({ required: false }) private current!: any;
  @Prop ({ required: false }) private cmsEntityType!: CmsEntityTypes;

  private handleChange(value: any) {
    if (this.$route.params.entityName === CmsEntityTypes.UserGroupConnections || 
    this.$route.params.entityName === CmsEntityTypes.Users) {
    this.$router.push({
      name: RouteNames.cmsIndex,
      params: {
        entityName: CmsEntityTypes.Users,
        entityId: value,
      },
    });
    }else{      
    this.$router.push({
      name: RouteNames.cmsIndex,
      params: {
        entityName: this.cmsEntityType,
        entityId: value,
      },
    });
    }

  }
}
